<template>
  <div class="AddQuestionnaire">
    <div class="crumbs">
      <router-link to="/questionnaire">
        <a-icon type="left" />
      </router-link>
      创建项目
    </div>

    <div class="add-type">
      <div class="type-title">请选择项目创建类型</div>
      <ul>
        <li
          @click="_selectActive(1)"
          :style="selectActive == 1 ? 'border: 2px solid #C0BEFC' : ''"
        >
          <img
            class="type-icon"
            src="http://sk-ims-cabinet.sikegroup.com/FkZMrbRt2npUZSw3aPSjpdp7-Ezz"
            alt=""
          />
          <div style="display: inline-block;vertical-align: middle">
            <div class="type-name">问卷调查</div>
            <div class="type-des">市场调查，产品调研，会员反馈</div>
          </div>
        </li>
        <li
          @click="_selectActive(2)"
          :style="selectActive == 2 ? 'border: 2px solid #C0BEFC' : ''"
        >
          <img
            class="type-icon"
            src="http://sk-ims-cabinet.sikegroup.com/FkKQKL4LdI-i6SJmGVd7-XD4FazH"
            alt=""
          />
          <div style="display: inline-block;vertical-align: middle">
            <div class="type-name">考试测评</div>
            <div class="type-des">教学考试，企业文化，趣味测试</div>
          </div>
        </li>
      </ul>
    </div>

    <div class="recommend-title">
      推荐{{ selectActive == 1 ? '问卷' : '试卷' }}
    </div>

    <div class="tabs-list">
      <ul>
        <li class="add-btn" @click="_skipCreated">
          <div class="content">
            <div class="add-btn-icon">
              <a-icon type="plus-circle" class="ant-menu-item-selected" />
            </div>
            <div class="add-btn-text ant-menu-item-selected">新建</div>
          </div>
        </li>

        <li
          v-for="(item, index) in infoData"
          @click="_skipSet(item.Type, item.Id)"
          @mouseover="_showListActive(index)"
          @mouseout="listActive = -1"
        >
          <div class="tabs-title">{{ item.Title }}</div>
          <div class="tabs-footer">
            共{{ item.TQCount }}题 / 已填写{{ item.Write_Count }}次
          </div>
          <div class="shade" v-show="listActive == index"></div>
          <div class="skip-text" v-show="listActive == index">
            <i class="iconfont icon-yulan" style="vertical-align: middle;"></i>
            快速预览
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
var user = JSON.parse(window.sessionStorage.getItem('userInfo'))
export default {
  name: 'AddQuestionnaire',
  data () {
    return {
      selectActive: 1,
      listActive: -1,
      infoData: []
    }
  },
  created () {
    let user = JSON.parse(window.sessionStorage.getItem('userInfo'))
    this.$message.loading('加载中...', 0)
    this._info(1)
  },
  methods: {
    _info (type) {
      this.$message.loading('加载中...', 0)
      this.$axios.get(5836101, { Type: type }, res => {
        this.infoData = res.data.data
        if (res.data.code == 1) {
        } else {
          this.$message.error(res.data.msg)
        }
        this.$message.destroy()
      })
    },
    _showListActive (i) {
      this.listActive = i
    },
    _skipSet (type, id) {
      this.$router.push({
        path: '/addQuestionnaire',
        query: { type: type, id: id, preview: 1 }
      })
    },
    _skipCreated () {
      this.$router.push({
        path: '/addQuestionnaire',
        query: { type: this.selectActive }
      })
    },
    _selectActive (i) {
      this.selectActive = i
      this._info(i)
    }
  }
}
</script>

<style scoped lang="less">
.AddQuestionnaire {
  .crumbs {
    position: relative;
    height: 64px;
    line-height: 64px;
    border-radius: 4px;
    background: #fff;
    text-align: center;
    color: #666;
    font-size: 16px;
    i {
      position: absolute;
      top: 50%;
      left: 25px;
      transform: translateY(-50%);
      font-size: 18px;
      color: #9d9d9d;
    }
  }
  .add-type {
    padding: 15px;
    margin-top: 20px;
    border-radius: 4px;
    background: #fff;
    .type-title {
      text-align: center;
      color: #333;
      font-size: 16px;
    }
    ul {
      display: flex;
      margin-top: 15px;
      margin-bottom: 25px;
      li {
        flex: 1;
        position: relative;
        display: inline-block;
        max-width: 450px;
        height: 100px;
        padding-top: 20px;
        border: 2px solid #f4f5f5;
        border-radius: 3px;
        margin-left: 20px;
        margin-right: 25px;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
        .type-icon {
          width: 60px;
          /*height: 60px;*/
          border-radius: 50%;
          margin-right: 15px;
          vertical-align: middle;
        }
        .type-name {
          color: #333;
          font-size: 16px;
          text-align: left;
        }
        .type-des {
          color: #999;
          font-size: 12px;
        }
      }
    }
  }
  .recommend-title {
    margin: 20px 0;
    color: #333;
    font-size: 16px;
  }
  .tabs-list {
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li {
        position: relative;
        width: 290px;
        height: 138px;
        padding: 15px;
        margin-right: 20px;
        margin-bottom: 25px;
        border-radius: 3px;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 20px 1px,
          rgba(0, 0, 0, 0.08) 0px 1px 4px;
        cursor: pointer;
        .shade {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #fff;
          opacity: 0.9;
        }
        .skip-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #7c79f9;
        }
        .tabs-title {
          color: #666;
          font-size: 14px;
        }
        .tabs-footer {
          position: absolute;
          bottom: 15px;
          left: 15px;
          color: #999;
          font-size: 12px;
        }
      }
      .add-btn {
        position: relative;
        .content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          .add-btn-icon {
            font-size: 28px;
          }
          .add-btn-text {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
